import api from "@/service/api";
import utils from "@/service/utils";


export default
    {
        namespace: true,
        state: {
            car:
            {
                car_id: 0,
                car_uniq: '',
                brand_name: '',
                model_name: '',
                car_year:2023,
                plate_code: '',
                is_active: 0,
                in_service: 0,
                drive_user_id: 0,
                current_km: 0,
                created_props: '',
                updated_props: '',
                deleted_props: ''
            },
            car_km:
            {
                car_km_id: 0,
                car_id: 0,
                user_id: 0,
                received_date: '',
                received_km: 0,
                delivered_date: '',
                delivered_km: 0,
                created_props: '',
                updated_props: '',
                deleted_props: ''
            },
            km_items: [],
        },
        mutations:
        {
            clearCargo(state) {
                
            },
        },
        actions:
        {
            saveCarKM({ state, commit, dispatch }, data) {
            
                let postData = { ...state.car_km };
                postData.car_uniq = state.car.car_uniq;
                
                return new Promise((resolve) => {
                    api.action('saveCarKM', postData).then(q => {
                        resolve(q);
                        if (q.success == false) {
                            utils.notificationToast("warning", "Bilgi", q.message);
                            return;
                        }
                        state.car = q.data.car;
                        state.car_km = q.data.car_km;
                        utils.notificationToast("success", "Bilgi", q.message);
                    })
                })

            }
        },


    }