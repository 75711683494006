import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import sf from './service-form';
import calendar from './calendar';
import global from './global';
import cargo from './cargo';
import car from './car';


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    sf: sf,
    global: global,
    // @ts-ignore
    calendar: calendar,
    cargo:cargo,
    car:car
  },
  // @ts-ignore
  strict: process.env.DEV,
})
