// @ts-nocheck

export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      metaTitle: 'Oturum Aç'
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      pageTitle: 'İstatistikler',
      metaTitle: 'İstatistikler',
      breadcrumb: [
        {
          text: 'İstatistikler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/dashboard-screen',
    name: 'dashboard-screen',
    component: () => import('@/views/reports/DeviceReportScreenList.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'İstatistikler',
      metaTitle: 'İstatistikler',
      breadcrumb: [
        {
          text: 'İstatistikler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/calendar/Calendar.vue'),
    meta: {
      pageTitle: 'Operasyon',
      metaTitle: 'Operasyon',
      breadcrumb: [
        {
          text: 'Operasyon',
          active: true,
        },
      ],
    },
  },
  {
    path: '/defintion-brand',
    name: 'definition-brand',
    component: () => import('@/views/setting/DefinitionBrands.vue'),
    meta: {
      pageTitle: 'Marka Tanımlamaları',
      metaTitle: 'Marka Tanımlamaları',
      breadcrumb: [
        {
          text: 'Ayarlar',
        },
        {
          text: 'Marka Tanımlamaları',
          active: true,
        },
      ],
    },
  },
  {
    path: '/defintion-tasktodo',
    name: 'definition-tasktodo',
    component: () => import('@/views/setting/DefinitionTaskTodo.vue'),
    meta: {
      pageTitle: 'Tanımlamalar',
      metaTitle: 'Teslimat Görev Tanımlamaları',
      breadcrumb: [
        {
          text: 'Ayarlar',
        },
        {
          text: 'Teslimat Görev Tanımlamaları',
          active: true,
        },
      ],
    },
  },
  {
    path: '/defintion-deviceproblem',
    name: 'definition-deviceproblem',
    component: () => import('@/views/setting/DefinitionDeviceProblem.vue'),
    meta: {
      pageTitle: 'Cihaz Sorunları',
      metaTitle: 'Cihaz Sorunları',
      breadcrumb: [
        {
          text: 'Ayarlar',
        },
        {
          text: 'Cihaz Sorunları',
          active: true,
        },
      ],
    },
  },
  {
    path: '/defintion-devicerequestofcustomer',
    name: 'definition-devicerequestofcustomer',
    component: () => import('@/views/setting/DefinitionCustomerDeviceRequest.vue'),
    meta: {
      pageTitle: 'Cihaz Onarım Talepleri',
      metaTitle: 'Cihaz Onarım Talepleri',
      breadcrumb: [
        {
          text: 'Ayarlar',
        },
        {
          text: 'Cihaz Onarım Talepleri',
          active: true,
        },
      ],
    },
  },
  {
    path: '/defintion-devicesolutionproposal',
    name: 'definition-devicesolutionproposal',
    component: () => import('@/views/setting/DefinitionDeviceSolutionProposal.vue'),
    meta: {
      pageTitle: 'Cihaz Çözüm Önerileri',
      metaTitle: 'Cihaz Çözüm Önerileri',
      breadcrumb: [
        {
          text: 'Ayarlar',
        },
        {
          text: 'Cihaz Çözüm Önerileri',
          active: true,
        },
      ],
    },
  },
  {
    path: '/defintion-deviceaction',
    name: 'definition-deviceaction',
    component: () => import('@/views/setting/DefinitionDeviceAction.vue'),
    meta: {
      pageTitle: 'Cihaz Uygulanan İşlemler',
      metaTitle: 'Cihaz Uygulanan İşlemler',
      breadcrumb: [
        {
          text: 'Ayarlar',
        },
        {
          text: 'Cihaz Uygulanan İşlemler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/defintion-deviceaction',
    name: 'definition-deviceaction',
    component: () => import('@/views/setting/DefinitionDeviceAction.vue'),
    meta: {
      pageTitle: 'Cihaza Uygulanan İşlemler',
      metaTitle: 'Cihaza Uygulanan İşlemler',
      breadcrumb: [
        {
          text: 'Ayarlar',
        },
        {
          text: 'Cihaza Uygulanan İşlemler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/defintion-deviceactiontobetaken',
    name: 'definition-deviceactiontobetaken',
    component: () => import('@/views/setting/DefinitionDeviceActionToBeTaken.vue'),
    meta: {
      pageTitle: 'Cihaza Uygulanacak İşlemler',
      metaTitle: 'Cihaza Uygulanacak İşlemler',
      breadcrumb: [
        {
          text: 'Ayarlar',
        },
        {
          text: 'Cihaz Uygulanacak İşlemler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/defintion-devicepreekspertise',
    name: 'definition-devicepreekspertise',
    component: () => import('@/views/setting/DefinitionDevicePreEkspertise.vue'),
    meta: {
      pageTitle: 'Cihaza Ön Ekspertiz',
      metaTitle: 'Cihaza Ön Ekspertiz',
      breadcrumb: [
        {
          text: 'Ayarlar',
        },
        {
          text: 'Cihaz Ön Ekspertiz',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-create',
    name: 'user-create',
    component: () => import('@/views/user/UserCreate.vue'),
    meta: {
      pageTitle: 'Kullanıcı Oluştur',
      metaTitle: 'Kullanıcı Oluştur',
      breadcrumb: [
        {
          text: 'Kullanıcılar',
        },
        {
          text: 'Yeni',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-update',
    name: 'user-update',
    component: () => import('@/views/user/UserUpdate.vue'),
    meta: {
      pageTitle: 'Kullanıcı Bilgileri',
      metaTitle: 'Kullanıcı Bilgileri',
      breadcrumb: [
        {
          text: 'Kullanıcılar',
          to: 'user-list'
        },
        {
          text: 'Kullanıcı Bilgileri',
        }
      ],
    },
  },
  {
    path: '/user-list',
    name: 'user-list',
    component: () => import('@/views/user/UserList.vue'),
    meta: {
      pageTitle: 'Kullanıcılar',
      metaTitle: 'Kullanıcılar',
      breadcrumb: [
        {
          text: 'Kullanıcılar',
          to: 'user-list',
          active: true
        }
      ],
    },
  },
  {
    path: '/customer-create',
    name: 'customer-create',
    component: () => import('@/views/customer/CustomerCreate.vue'),
    meta: {
      pageTitle: 'Müşteri Oluştur',
      metaTitle: 'Müşteri Oluştur',
      breadcrumb: [
        {
          text: 'Müşteriler',
          to: "customer-list",
        },
        {
          text: 'Yeni',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-update',
    name: 'customer-update',
    component: () => import('@/views/customer/CustomerUpdate.vue'),
    meta: {
      pageTitle: 'Müşteri Güncelle',
      metaTitle: 'Müşteri Güncelle',
      breadcrumb: [
        {
          text: 'Müşteriler',
          to: "customer-list",
        },
        {
          text: 'Müşteri Bilgileri',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer-list',
    name: 'customer-list',
    component: () => import('@/views/customer/CustomerList.vue'),
    meta: {
      pageTitle: 'Müşteri Listesi',
      metaTitle: 'Müşteri Listesi',
      breadcrumb: [
        {
          text: 'Müşteriler',
          to: "customer-list",
        },
        {
          text: 'Kişiler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company-list',
    name: 'company-list',
    component: () => import('@/views/customer/CustomerList.vue'),
    meta: {
      pageTitle: 'Müşteri Listesi',
      metaTitle: 'Müşteri Listesi',
      breadcrumb: [
        {
          text: 'Müşteriler',
          to: "company-list",
        },
        {
          text: 'Müşteriler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/service/Service.vue'),
    meta: {
      pageTitle: 'Servisler',
      metaTitle: 'Servisler',
      breadcrumb: [
        {
          text: 'Servisler',
          to: "services",
        },
        {
          text: 'Servis Formu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/collections',
    name: 'collections',
    component: () => import('@/views/collection/CollectionList.vue'),
    meta: {
      pageTitle: 'Tahsilatlar',
      metaTitle: 'Tahsilatlar',
      breadcrumb: [
        {
          text: 'Tahsilatlar',
          to: "collection",
        },
        {
          text: 'Tahsilat Listesi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/collection',
    name: 'collection',
    component: () => import('@/views/collection/CollectionDetail.vue'),
    meta: {
      pageTitle: 'Tahsilatlar',
      metaTitle: 'Tahsilat Oluştur',
      breadcrumb: [
        {
          text: 'Tahsilatlar',
          to: "collections",
        },
        {
          text: 'Tahsilat Bilgileri',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tservice',
    name: 'tservice',
    component: () => import('@/views/service/TDeviceRepairTechnicalReport.vue'),
    meta: {
      pageTitle: 'Servis Bilgileri',
      metaTitle: 'Servis Bilgileri',
      breadcrumb: [
        {
          text: 'Servisler',
          to: "services",
        },
        {
          text: 'Servis Formu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/service-create',
    name: 'service-create',
    component: () => import('@/views/service/Service.vue'),
    meta: {
      pageTitle: 'Servisler',
      metaTitle: 'Servisler',
      breadcrumb: [
        {
          text: 'Servisler',
          to: "services",
        },
        {
          text: 'Servis Formu',
          active: true,
        },
      ],
    },
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/service/ServiceList.vue'),
    meta: {
      pageTitle: 'Servisler',
      metaTitle: 'Servisler',
      breadcrumb: [
        {
          text: 'Servisler',
          active: true,
        },
      ],
    },
  },
  {
    path: '/service/tasks',
    name: 'service-tasks',
    component: () => import('@/views/service/ServiceTask.vue'),
    meta: {
      pageTitle: 'Servis Görevleri',
      metaTitle: 'Servis Görevleri',
      breadcrumb: [
        {
          text: 'Servis Görevleri',
          active: true,
        },
      ],
    },
  },
  {
    path: '/task/list',
    name: 'task-list',
    component: () => import('@/views/task/TaskList.vue'),
    meta: {
      pageTitle: 'Görevler',
      metaTitle: 'Görevler',
      breadcrumb: [
        {
          text: 'Görevler',
        },
        {
          text: 'Görev Listesi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/task/me',
    name: 'task-list-me',
    component: () => import('@/views/task/TaskListMy.vue'),
    meta: {
      pageTitle: 'Görevlerim',
      metaTitle: 'Görevlerim',
      breadcrumb: [
        {
          text: 'Görevler',
          to:'/task/list'
        },
        {
          text: 'Görev Listem',
          active: true,
        },
      ],
    },
  },
  {
    path: '/task/create',
    name: 'task-create',
    component: () => import('@/views/task/TaskCreate.vue'),
    meta: {
      pageTitle: 'Görevler',
      metaTitle: 'Görevler',
      breadcrumb: [
        {
          text: 'Görevler',
          to:'/task/list'
        },
        {
          text: 'Yeni Görev Oluştur',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cargotracking/create',
    name: 'cargotracking-create',
    component: () => import('@/views/cargo/CargoCreate.vue'),
    meta: {
      pageTitle: 'Kargo Takibi',
      metaTitle: 'Kargo Takibi',
      breadcrumb: [
        {
          text: 'Yeni Oluştur',
          active:true
        },
      ],
    },
  },
  {
    path: '/cargotracking/detail',
    name: 'cargotracking-detail',
    component: () => import('@/views/cargo/CargoDetail.vue'),
    meta: {
      pageTitle: 'Kargo Takibi',
      metaTitle: 'Kargo Takibi',
      breadcrumb: [
        {
          to: "/cargotracking/list",
          text: 'Kargo Takip Listesi',
        },
        {
          text: 'Kargo Takip Bilgileri',
        },
      ],
    },
  },
  {
    path: '/cargotracking/list',
    name: 'cargotracking-list',
    component: () => import('@/views/cargo/CargoList.vue'),
    meta: {
      pageTitle: 'Kargo Takibi',
      metaTitle: 'Kargo Takibi',
      breadcrumb: [
        {
          text: 'Kargo Takibi',
          active:true
        },
      ],
    },
  },
  {
    path: '/carstracking/list',
    name: 'carstracking-list',
    component: () => import('@/views/car/CarList.vue'),
    meta: {
      pageTitle: 'Araç Takibi',
      metaTitle: 'Araç Takibi',
      breadcrumb: [
        {
          text: 'Araç Takibi',
          active:true
        },
      ],
    },
  },
  {
    path: '/carstracking/createcar',
    name: 'carstracking-createcar',
    component: () => import('@/views/car/CarCreate.vue'),
    meta: {
      pageTitle: 'Yeni Araç Oluştur',
      metaTitle: 'Yeni Araç Oluştur',
      breadcrumb: [
        {
          text: 'Yeni Araç Oluştur',
          active:true
        },
      ],
    },
  },
  {
    path: '/carstracking/detailcar',
    name: 'carstracking-detailcar',
    component: () => import('@/views/car/CarDetail.vue'),
    meta: {
      pageTitle: 'Araç Bilgileri',
      metaTitle: 'Araç Bilgileri',
      breadcrumb: [
        {
          text: 'Araç Bilgileri',
          active:true
        },
      ],
    },
  },
  {
    path: '/carstracking/history',
    name: 'carstracking-history',
    component: () => import('@/views/car/CarHistory.vue'),
    meta: {
      pageTitle: 'Araç Geçmişi',
      metaTitle: 'Araç Geçmişi',
      breadcrumb: [
        {
          text: 'Araç Geçmişi',
          active:true
        },
      ],
    },
  },
  {
    path: '/device/history',
    name: 'device-history',
    component: () => import('@/views/device/DeviceHistory.vue'),
    meta: {
      pageTitle: 'Cihaz Geçmişi',
      metaTitle: 'Cihaz Geçmişi',
      breadcrumb: [
        {
          text: 'Cihaz Geçmişi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      pageTitle: 'Ayarlar',
      metaTitle: 'Ayarlar',
      breadcrumb: [
        {
          text: 'Hesap Ayarları',
          active: true,
        },
      ],
    },
  },
  {
    path: '/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      metaTitle: 'Yetkiniz Bulunmuyor'
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      pageTitle: 'Sayfa Bulunmuyor',
      metaTitle: 'Sayfa Bulunmuyor'
    },
  },
  {
    path: '/print/service-receipt',
    name: 'printreceipt',
    component: () => import('@/views/print/CustomerServiceReceipt.vue'),
    meta: {
      layout: '',
    },
  },
  {
    path: '/print/filetag',
    name: 'printfiletag',
    component: () => import('@/views/print/FileTag.vue'),
    meta: {
      layout: 'full',
    },
  },


]
