import api from "@/service/api";
import utils from "@/service/utils";

export default {
    namespace: true,
    state: {
        currencies: utils.getCurrencies({ viewtype: "vselect" }),
        service_reason_take: utils.getServiceReason({ viewtype: "vselect", type: "service_take_reason" }),
        service_reason_send: utils.getServiceReason({ viewtype: "vselect", type: "service_send_reason" }),
        service_vehicles: utils.getDefinitionsForSelect('service_vehicle'),
        paymentmethods: utils.getDefinitionsForSelect('payment_method'),
        cargo_companies: utils.getDefinitionsForSelect('cargo_company',{ label:'Seçilmedi',value:'0' }),
        users: utils.getUsers({ viewtype: "vselect" }),
        yesno: utils.getYesNo(),
        status_delivery: utils.getStatusDelivery({ viewtype: "vselect" }),
        delivery_priority: utils.definitionDeliveryPriority({ viewtype: "vselect" }),
        delivery_reason:utils.getDeliveryReason({ viewtype:"vselect" }),
        task_todo: utils.definitionTaskTodo({ viewtype: "vselect" }),
        device_types:utils.getDeviceTypes(),
        device_customer_request: utils.getCustomerDeviceRequest(),
        device_problems: utils.getDeviceProblems(),
        device_pre_ekspertise: utils.getDevicePreEkspertise(),
        device_brands: utils.getDefinitionsForSelect('brand'),
        device_problem_types: utils.getDeviceProblemTypes(),
        device_problem_status: utils.getDeviceProblemStatus(),
        device_tests: utils.getDeviceTests(),
        device_status: utils.getDeviceStatus({ viewtype: "vselect" }),
        device_customer_information_status: utils.getDeviceCustomerInformationStatus({ viewtype: "vselect" }),
        customer_information_channel: utils.getCustomerInformationChannels({ viewtype: "vselect" }),
        customer_information_status:utils.getCustomerInformationStatus(),
        customer_information_category:utils.getCustomerInformationCategory({ viewtype: "vselect" }),
        customer_report_type:utils.customer_report_type(),
        service_information_showto: utils.getDeviceInformationShowTo(),
        device_warrant_types: utils.getDeviceWarrantTypes(),
        device_warrant_periods: utils.getDeviceWarrantPeriods(),
        banks: utils.getBanks({ viewtype: "vselect" }),
        kdv: utils.getKDV({ viewtype: "vselect" }),

        currency_type: utils.getCurrencyType({ viewtype: "vselect" }),
        proposal_status: utils.getProposalStatus({ viewtype: "vselect" }),
        outservice_status: utils.getOutServiceStatus({ viewtype: "vselect" }),
        task_status:utils.getTaskStatus({ viewtype: "vselect" }),
        task_types:utils.getTaskTypes(),
        access_level:utils.getUserAccessLevel({ viewtype: "vselect" }),
        payment_category:utils.getPaymentCategories({ viewtype: "vselect" }),
        payment_methods:utils.getPayMethods({ viewtype: "vselect" }),
        device_action_tobetaken:utils.getDeviceActionToBeTaken({ viewtype:"vselect" }),
        payment_status:utils.getPaymentStatus(),
        delivery_types:utils.getDeliveryTypes(),
        cities:utils.getCities({ viewtype: "vselect" }),
        offices:utils.getOffices({ viewtype:"vselect" }),
        device_backup_status: utils.getDefinitionsForSelect('device_backup_status',{ label:'Seçilmedi',value:'0' }),

        modal_send_sms_notification:
        {
            customer:null,
            entity_type:'', //device|cargo|sf
            entity_uniq:null,
            mode:'send',
            sms_definition:null
        }

    },
    mutations: {
        setUsers(state, data) {
            state.users = data;
        }
    },
    actions: {

    },
    getters: {

    }
}