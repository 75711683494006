import api from "@/service/api";
import utils from "@/service/utils";


export default
    {
        namespace: true,
        state: {
            filter:
            {
                datepicker: { start: null, end: null },
                start_date:null,
                end_date:null,
                show_staff_task: false,
                show_immediate: false,
                task_pending: true,
                task_progress: true,
                task_complated: true,
                device_task: false
            },
            tabs:
            {
                task: { disabled: false, visible: true },
                delivery: { disabled: false, visible: true },
                customer: { disabled: false, visible: true },
                device: { disabled: true, visible: true }
            },

            event: {},
            events: [],
            is_delivery_task: false
        },
        actions:
        {
            calendarSave({ state, dispatch, rootState }) {

                let task = rootState.sf.task;
                let delivery = rootState.sf.delivery;
                let sf = rootState.sf;

                if (!task.task_definition_id || task.task_definition_id <= 0) {
                    utils.notificationToast("warning", "Bilgi", "Görev tanımı seçin.");
                    return;
                }
                if (!task.staff_id || task.staff_id <= 0) {
                    utils.notificationToast("warning", "Bilgi", "Personel seçin.");
                    return;
                }
                if (!task.planned_date || task.planned_date == '') {
                    utils.notificationToast("warning", "Bilgi", "Görev tarihini seçin.");
                    return;
                }
                if (state.is_delivery_task) {
                    if (!delivery.district_id || delivery.district_id <= 0) {
                        utils.notificationToast("warning", "Bilgi", "Teslimatın gerçekleşeceği ilçeyi seçin.");
                        return;
                    }
                    if (sf.customer == null) {
                        utils.notificationToast("warning", "Bilgi", "Müşteriyi seçin.");
                        return;
                    }
                    if (!sf.sform.sf_date || sf.sform.sf_date.length <= 9) {
                        utils.notificationToast("warning", "Bilgi", "Servis tarihini seçin.");
                        return;
                    }
                }
                let postData =
                {
                    task: { ...task },
                    delivery: { ...delivery },
                    customer: { ...sf.customer },
                    sform: { ...sf.sform },
                    is_delivery_task: state.is_delivery_task ? 1 : 0

                }

                api.action('saveCalendarTask', postData).then(q => {
                    if (q.success == false) {
                        utils.notificationToast("warning", "Bilgi", q.message);
                        return;
                    } else {
                        utils.notificationToast("success", "Bilgi", q.message);
                    }
                    dispatch("getEvents");
                })

                //planned_date
            },
            getEvents({ state, commit, dispatch }) {
                let start = utils.moment(state.filter.datepicker.start).format('YYYY-MM-DD');
                let end = utils.moment(state.filter.datepicker.end).format('YYYY-MM-DD')
                let postData =
                {
                    start: start,
                    end: end,
                    show_immediate: state.filter.show_immediate ? 1 : 0,
                    show_task_staff:state.filter.show_staff_task ? 1:0,
                    task_pending: state.filter.task_pending ? 1 : 0,
                    task_progress: state.filter.task_progress ? 1 : 0,
                    task_complated: state.filter.task_complated ? 1 : 0,
                    device_task: state.filter.device_task ? 1 : 0
                }

                return new Promise((resolve, reject) => {
                    api.action('getCalendarTask', postData).then(q => {
                        if (q.success == false) {
                            utils.notificationToast("warning", "Bilgi", q.message);
                            resolve(q.data);
                            return;
                        }
                        state.events = q.data;
                        resolve(q.data);
                    })
                });

            },
            triggerChangeCalendarTask({ state, rootState, commit, dispatch }) {
                state.tabs =
                {
                    task: { disabled: false, visible: true },
                    delivery: { disabled: false, visible: false },
                    customer: { disabled: false, visible: false },
                    device: { disabled: true, visible: false }
                }
               
                if(state.event == null || Object.keys(state.event).length == 0)
                {
                    console.log('event yok');
                    return;
                }

                if(state.event.event_type == 'task')
                {
                    state.tabs =
                    {
                        task: { disabled: false, visible: true },
                        delivery: { disabled: false, visible: false },
                        customer: { disabled: false, visible: false },
                        device: { disabled: true, visible: false }
                    }
                }
                else if(state.event.event_type == 'device_repair')
                {
                    state.tabs =
                    {
                        task: { disabled: false, visible: true },
                        delivery: { disabled: false, visible: false },
                        customer: { disabled: false, visible: false },
                        device: { disabled: true, visible: false }
                    }
                }
                else if(state.event.event_type == 'delivery' || state.is_delivery_task)
                {
                    state.tabs =
                    {
                        task: { disabled: false, visible: true },
                        delivery: { disabled: false, visible: true },
                        customer: { disabled: false, visible: true },
                        device: { disabled: true, visible: false }
                    }
                }
                else if(state.event.event_type == 'device')
                {
                    state.tabs =
                    {
                        task: { disabled: false, visible: false },
                        delivery: { disabled: false, visible: false },
                        customer: { disabled: false, visible: true },
                        device: { disabled: false, visible: true }
                    }
                }
            },
            startDeliveryTask({ state,rootState,commit,data })
            {
                let task = rootState.sf.task;
                let postData = {  task_id:task.task_id  }
                return new Promise((resolve, reject) => {
                    api.action('startDeliveryTask', postData).then(q => {
                        if (q.success == false) {
                            utils.notificationToast("warning", "Bilgi", q.message);
                        }
                        else {
                            utils.notificationToast("success", "Bilgi", q.message);
                        }
                        resolve(q);
                    })
                });
            }
        },

    }