import api from "@/service/api";
import utils from "@/service/utils";


export default
    {
        namespace: true,
        state: {
            cargo:
            {
                c_id: 0,
                c_uniq: '',
                type: '',
                planned_date: '',
                complated_date: '',
                cargo_company_id: '0',
                customer_id: 0,
                cargo_tracking_code: '',
                customer_will_pay: '0',
                cargo_price: '',
                target_office_id: 0,
                city_id: '0',
                district_id: '0',
                address: '',
                notes: '',
                created_props: '',
                updated_props: '',
                deleted_props: ''
            },
            cargo_item:
            {
                ci_id: 0,
                ci_uniq: '',
                c_id: 0,
                type: '',
                item_name: '',
                device_id: 0,
                created_props: '',
                updated_props: '',
                deleted_props: ''
            },
            cargo_items: [],
            customer: null,
            districts: [],
            logs:[],
            sf:null
        },
        mutations:
        {
            clearCargo(state) {
                state.cargo = {
                    c_id: 0,
                    c_uniq: '',
                    type: '',
                    planned_date: '',
                    complated_date: '',
                    cargo_company_id: '0',
                    customer_id: 0,
                    cargo_tracking_code: '',
                    customer_will_pay: '0',
                    cargo_price: '',
                    target_office_id: 0,
                    city_id: '0',
                    district_id: '0',
                    address: '',
                    notes: '',
                    office_id: 0,
                    created_props: '',
                    updated_props: '',
                    deleted_props: ''
                }
            },
        },
        actions:
        {
            saveCargo({ state, commit, dispatch }, data) {
                if (state.cargo.customer_id <= 0) {

                    utils.notificationToast('warning', 'Bilgi', 'Müşteri seçin.');
                    return;
                }
                if (['take', 'send'].indexOf(state.cargo.type) == -1) {
                    utils.notificationToast('warning', 'Bilgi', 'Kargo yönünü seçin.');
                    return;
                }
                if (state.cargo.customer_will_pay == null) {
                    utils.notificationToast('warning', 'Bilgi', 'Ödeme durumunu kontrol edin.');
                    return;
                }
                if (state.cargo.city_id <= 0) {
                    utils.notificationToast('warning', 'Bilgi', 'Şehir seçin.');
                    return;
                }
                if (state.cargo.district_id < 0) {
                    utils.notificationToast('warning', 'Bilgi', 'İlçe seçin.');
                    return;
                }

                let postData = { ...state.cargo };
                return new Promise((resolve) => {
                    api.action('saveCargo', postData).then(q => {
                        resolve(q);
                        if (q.success == false) {
                            utils.notificationToast("warning", "Bilgi", q.message);
                            return;
                        }
                        utils.notificationToast("success", "Bilgi", q.message);
                    })
                })

            },
            getCargoDetail(context, data) {

                let postData = { c_uniq: data.uniq };
                api.action("getCargoDetail", postData).then(q => {
                    if (!q.success) {
                        utils.notificationToast('warning', 'Geçersiz Servis', 'Geçersiz bir kargo bilgisine erişmeyi denediniz. Lütfen bilgileri kontrol edip yeniden deneyin.');
                        return;
                    }
                    
                    q.data.cargo.customer_will_pay = q.data.cargo.customer_will_pay.toString()
                    context.state.cargo = q.data.cargo;
                    context.state.cargo_items = q.data.cargo_items;
                    context.state.customer = q.data.customer;
                    context.state.logs = q.data.logs;
                    context.state.districts = q.data.districts;
                    context.state.sf = q.data.sf;
                    utils.setTitle(`Kargo Takibi #${q.data.cargo.c_id}`);  
                })
            },
            saveCargoItem({ state, commit, dispatch }) {

                let postData = state.cargo_item;
                postData.c_id = state.cargo.c_id;
                api.action("saveCargoItem", postData).then(q => {
                    if (!q.success) {
                        utils.notificationToast('warning', 'Bilgi', q.message);
                        return;
                    }
                    utils.notificationToast('success', 'Bilgi', 'Paket içeriği güncellendi');
                    dispatch("getCargoDetail", { uniq: state.cargo.c_uniq });
                })
            },
            cargoAccept({ state, commit, dispatch })
            {
                let postData = { c_uniq:state.cargo.c_uniq };
                api.action("cargoAccept", postData).then(q => {
                    if (!q.success) {
                        utils.notificationToast('warning', 'Bilgi', q.message);
                        return;
                    }
                    utils.notificationToast('success', 'Bilgi', 'Kargo kabul edildi ve servis formuna dönüştürüldü.');
                    dispatch("getCargoDetail", { uniq: state.cargo.c_uniq });
                })
            },
            sendSMSCargoAcceptFromOffice({ state, commit, dispatch }) 
            {
                let postData = { c_uniq:state.cargo.c_uniq }
                api.action("sendSMSCargoAcceptFromOffice",{...postData}).then(q => {
                    if (!q.success) {
                        utils.notificationToast('warning', 'Bilgi',q.message);
                        return;
                    }
                    else
                    {
                        utils.notificationToast('success', 'Bilgi',q.message);
                    }
                })
            }
        },


    }