// @ts-nocheck

import moment from "moment";
import { nanoid, customRandom, customAlphabet } from "nanoid";
import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

class Utils {

    constructor() { }

    static defaultSweetStyle =
        {
            popup: 'rounded-lg pr-5 pl-5',
            header: 'p-1',
            title: '...',
            actions: 'pt-2 pb-2',
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
        }

    static userRoles =
        [
            "create_user",
            "update_user",
            "delete_user",
            "view_user",
            "list_user",
            "update_role",
            "create_definition",
            "update_definition",
            "delete_definition",
            "view_definition",
            "create_customer",
            "update_customer",
            "delete_customer",
            "view_customer",
            "list_customer"
        ]

    static logout() {
        Vue.$router.push('/login');
        localStorage.clear();
    }

    static getActiveUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    static userHasRole(role_name) {
        let user_roles = this.getActiveUser().roles;
        let d = user_roles.includes(role_name);
        return d;
    }

    static setTitle(title) {
        document.title = title;
    }

    static setError(errCatch, page, data) {
        let error =
        {
            'message': errCatch.message,
            stack: errCatch.stack
        }
        let u = JSON.parse(localStorage.getItem('user'));
        let user = {
            user_id: u.user_id,
            namesurname: u.user_namesurname
        }

        let item =
        {
            'type': 'error',
            'error': error,
            'page': page,
            'user': user,
            date: moment().format('DD.MM.YYYY HH:mm'),
            'data': data
        }
        let errors = JSON.parse(localStorage.getItem('errors'));
        errors = errors == null ? [] : errors;
        errors.push(item);
        localStorage.setItem("errors", JSON.stringify(errors));
    }

    static getDefinition(definition_id, select = '') {

        let result = null;
        let definitions = JSON.parse(localStorage.getItem('definitions'));
        let definition = definitions.find(q => q.definition_id == definition_id);
        if (definition != null) {
            result = select != '' ? definition[select] : definition;
        }
        return result;
    }

    static getDefinitionName(definition_id) {

        let definition = JSON.parse(localStorage.getItem('definitions')).find(q => q.definition_id == definition_id);
        return definition != null ? definition.name : null;
    }

    static getCities(params = null) {
 
        let result = [];
        let data = JSON.parse(localStorage.getItem('definition_city'));
        if(data == null) return [];

        if (params != null && params.viewtype == 'vselect') {
            data.forEach(q => {
                result.push({ label: `${q.city_name} (${q.city_id})`, value: q.city_id })
            });
        }
        else {
            result = data;
        }
        return result;
    }

    static getDistricts(cityid = '', params = null) {
       
        let result = [];
        let items = JSON.parse(localStorage.getItem('definition_district'));
        result = items;
        if (cityid != '') {
            result = items.filter(q => q.city_id == cityid);
        }

        if (params != null && params.viewtype == 'vselect') {
            let temp = [];
           
            result.forEach(qq => {
                let item =
                {
                    label: qq.district_name,
                    value: qq.district_id,
                    district_id: qq.district_id
                }
                temp.push(item)
            })
            result = temp;
        }
        result.unshift({ label:'Seçin',value:'0',district_id:'0' });
        return result;
    }

    static getOffices(params)
    {
        let result = [];
        let items = JSON.parse(localStorage.getItem('offices'));
        if(!items) return result;
        result = items;
 
        if (params != null && params.viewtype == 'vselect') {
            let temp = [];
            result.forEach(qq => {
                let item =
                {
                    label: qq.title,
                    value: qq.office_id,
                }
                temp.push(item)
            })
            result = temp;
        }
        return result;
    }

    static getServiceReason(params) {

        let result = null;
        let definitions = JSON.parse(localStorage.getItem('definitions'));
        if (definitions == null) {
            return result;
        }
        definitions = definitions.filter(q => q.type == params.type);
        result = definitions;
        if (params.viewtype == 'vselect') {
            let temp = [];
            definitions.forEach(qq => {
                let item = {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                temp.push(item)
            })
            result = temp;
        }
        return result;
    }

    static getDeviceTypes(params) {
        let result = [];
        let definitions = this.getDefinitionsByType('device_type');
        if (params != null && params.viewtype == 'vselect') {
            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        }
        else {
            result = definitions;
        }
        return result;
    }

    static getDefinitionsByType(type) {
        let result = [];
        let definitions = this.getDefinitions();
        result = definitions.filter(q => q.type == type);
        return result;
    }

    static getDefinitions() {
        let result = [];
        if (JSON.parse(localStorage.getItem('definitions'))) {
            result = JSON.parse(localStorage.getItem('definitions'));
        }
        return result;
    }

    static getPayMethods(params) {
        let result = [];
        let definitions = this.getDefinitionsByType('payment_method');

        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        return result;
    }

    static getCargoCompanies(params) {

        let result = [];
        let definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'cargo_company');
        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        return result;
    }

    static getDeliveryTypes(val = '') {
        let items = [{
            label: "Müşteriden Alım",
            value: "take",
        },
        {
            label: "Müşteriye Gönderim",
            value: "send",
        }
        ]
        if (val == '') {
            return items;
        } else {
            return items.find(q => q.value == val);
        }

    }

    static getCurrencies(params) {

        let result = [];

        let definitions = JSON.parse(localStorage.getItem('definitions'));
        if (definitions == null)
            return result;

        definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'currency');
        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        return result;
    }

    static getDefinitionsForSelect(type, definitionDefault  = { label:'Seçilmedi' , value:''  } ) {

        let result = [];
        let definitions = JSON.parse(localStorage.getItem('definitions'));
        if (definitions == null) {
            return result;
        }

        result.push({
            label:definitionDefault.label,
            value:definitionDefault.value,
            type:type,
            definition_id:"0",
            definition_name:''
        })
        definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == type);
        definitions.forEach(qq => {
            let item =
            {
                label: qq.name,
                value: qq.definition_id,
                definition_id: qq.definition_id,
                definition_name: qq.definition_name,
                is_active: qq.is_active,
                type: qq.type
            }
            result.push(item)
        })

        return result;
    }

    static getCustomerDeviceRequest(params) {
        let result = [];
        let definitions = JSON.parse(localStorage.getItem('definitions'));
        if (definitions == null) {
            return result;
        }

        definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'customer_device_request');

        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        return result;
    }

    static getDeviceProblems(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'device_problem');
        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        return result;
    }

    static getDevicePreEkspertise(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'device_pre_ekspertise');
        if (params != null && params.viewtype == 'vselect') {
            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        return result;
    }

    static getDeviceSolutionProposal(params) {
        //cihaza uygulanacak işlemler
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'device_solution_proposal');
        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        return result;
    }

    static getDeviceAction(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'device_action');
        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        return result;
    }

    static getDeviceActionToBeTaken(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'device_action_tobetaken');
        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        result.unshift({
            label:'Seçin',
            value:'0',
            definition_id:'0'
        });
        return result;
    }

    static getBrands(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'brand');
        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        return result;
    }

    static getYesNo(params) {
        let result = [{
            'label': 'Hayır',
            'value': '0'
        }, {
            'label': 'Evet',
            'value': '1'
        }];
        return result;
    }

    static definitionsFormatVSelect(params) {
        let result = [];
        params.data.forEach(q => {
            result.push({
                'label': q.name,
                'value': q.definition_id
            });
        })
        return result;
    }

    static definitionDeliveryPriority(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let data = JSON.parse(localStorage.getItem('definitions'));
        if (data != null) {
            result.push({ 'label':'Seçin',value:'0' });
            data = data.filter(q => q.type == 'delivery_priority');
            data.forEach(q => {
                result.push({
                    'label': q.name + ' [' + q.attr_1 + ']',
                    'value': q.definition_id
                });
            })
        }
        return result;
    }

    static getDeliveryReason(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let data = JSON.parse(localStorage.getItem('definitions'));
        if (data != null) {
            data = data.filter(q => q.type == 'delivery_reason');
            console.clear();
            console.log('dara', data);
            data.forEach(q => {
                result.push({
                    'label': q.name,
                    'value': q.definition_id
                });
            })
        }
        return result;
    }

    static definitionTaskTodo(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let data = JSON.parse(localStorage.getItem('definitions'));
        if (data != null) {
            data = data.filter(q => q.type == 'task_todo');
            data.forEach(q => {
                result.push({
                    'label': q.name,
                    'value': q.definition_id
                });
            })
        }

        result.unshift({
            label:'Seçin',
            value:'0',
            user:null
        });


        return result;
    }


    static getUsers(params) {
        let result = [];
        result.push({
            label:'Seçin',
            value:'0',
            user:null
        });
        let users = JSON.parse(localStorage.getItem('users'));
        if (users == null) return result;
        if (params != null && params.viewtype == 'vselect') {
            users.forEach(qq => {
                let item =
                {
                    label: qq.user_namesurname,
                    value: qq.user_id,
                    user: qq
                }
                result.push(item)
            })
        } else {
            result = users;
        }
        return result;
    }

    static getUser(userid, select = '') {
        let result = null;
        let users = JSON.parse(localStorage.getItem('users'));
        if (users == null) return result;
        let user = users.find(q => q.user_id == userid);
        if (user != null) {
            result = user;
            if (select != '') result = user[select];
        }
        return result;
    }

    static getStatusDelivery(params) {
        let result = [];
        if (localStorage.getItem('delivery_status') == null) return [];
        let data = JSON.parse(localStorage.getItem('delivery_status'));
        if (data != null) {
            if (params != null && params.viewtype == 'vselect') {
                data.forEach(q => {
                    result.push({ label: q.title, value: q.val })
                });
            }
            else {
                result = data;
            }
        }
        return result;
    }


    static getBanks(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'bank');
        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        result.unshift({ label:'Seçin' , value:'0' });
        return result;
    }


    static getProposalStatus(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'proposal_status');
        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        return result;
    }

    static getOutServiceStatus(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'outservice_status');
        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_name,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        return result;
    }

    static getTaskStatus(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'task_status');
        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_name,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        return result;
    }

    static getTaskTypes(val = '') {
        let items = [{
            label: "Görev",
            value: "task",
        },
        {
            label: "Teslimat",
            value: "delivery",
        },
        {
            label: "Cihaz Onarım",
            value: "device_repair",
        }
        ]
        if (val == '') {
            return items;
        } else {
            return items.find(q => q.value == val);
        }
    }

    static getDeviceCustomerInformationStatus(params) {

        let result = [];
        let data = [
            {
                label: "Bilgilendirme Yapıldı",
                value: "information_complated",
            },
            {
                label: "Bilgilendirme Yapılacak",
                value: "information_pending",
            },
            {
                label: "Taslak",
                value: "draft",
            }
        ]
        if (data != null) {
            if (params != null && params.viewtype == 'vselect') {
                data.forEach(q => {
                    result.push({ label: q.title, value: q.val })
                });
            }
            else {
                result = data;
            }
        }
        return result;
    }

    static getCustomerInformationChannels(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'customer_information_channel');
        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                    definition_id: qq.definition_id,
                    definition_name: qq.definition_name,
                    is_active: qq.is_active,
                    type: qq.type
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        result.unshift({ label:'Seçin',value:'0' });
        return result;
    }

    static getCustomerInformationStatus(val = '') {
        let items = [{
            label: "Beklemede",
            value: "pending",
        },
        {
            label: "Tamamlandı",
            value: "complated",
        }
        ]
        if (val == '') {
            return items;
        } else {
            return items.find(q => q.value == val);
        }
    }

    static getPaymentStatus(val = '') {
        let items = [{
            label: "Beklemede",
            value: "pending",
        },
        {
            label: "Tamamlandı",
            value: "complated",
        },
        {
            label: "İptal",
            value: "canceled",
        }
        ]
        if (val == '') {
            return items;
        } else {
            return items.find(q => q.value == val);
        }
    }

    static getCustomerInformationCategory(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return [];
        let definitions = JSON.parse(localStorage.getItem('definitions')).filter(q => q.type == 'customer_information_category');
        if (params != null && params.viewtype == 'vselect') {

            definitions.forEach(qq => {
                let item =
                {
                    label: qq.name,
                    value: qq.definition_id,
                }
                result.push(item)
            })
        } else {
            result = definitions;
        }
        result.unshift({ label:'Seçin',value:'0' })
        return result;
    }

    static getDeviceInformationShowTo() {
        let result = [
            { text: 'Müşteri Görebilir', value: 'customer' },
            { text: 'Personeller Görebilir', value: 'staff' },
            { text: '2.Seviye Erişim İzni Görebilir', value: "2" }
        ];
        return result;
    }

    static getDeviceWarrantTypes(val = '') {
        let items = [
            { label: 'Servis Garantisi', value: 'service' },
            { label: 'Parça Garantisi', value: 'part' },
            { label: 'Yetkili Satıcı Garantisi', value: "dealer" }
        ];
        if (val == '') {
            return items;
        } else {
            return items.find(q => q.value == val);
        }

    }

    static getDeviceWarrantPeriods() {
        let result = [
            { label: '3 Ay', value: '3M' },
            { label: '6 Ay', value: '6M' },
            { label: '9 Ay', value: '9M' },
            { label: '15 Ay', value: '15M' },
            { label: '18 Ay', value: '18M' },
            { label: '1 Yıl', value: '1Y' },
            { label: '2 Yıl', value: '2Y' },
        ];
        return result;
    }

    static getKDV(params) {
        let result = [
            { label: '0', value: '0' },
            { label: '%1', value: '1' },
            { label: '%18', value: '18' },
            { label: '%24', value: '24' },
            { label: '%36', value: '36' }
        ];

        return result;
    }

    static getCurrencyType(params) {
        let result = [
            { label: 'TL', value: 'TL' },
            { label: 'USD', value: 'USD' },
            { label: 'EUR', value: 'EUR' }
        ];

        return result;
    }


    static getDeviceTests(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return result;
        let items = JSON.parse(localStorage.getItem('definitions'));
        let data = items.filter(q => q.type == 'device_test');
        if (data != null) {
            if (params != null && params.viewtype == 'vselect') {
                data.forEach(q => {
                    result.push({ label: q.title, value: q.val })
                });
            }
            else {
                result = data;
            }
        }
        return result;
    }

    static getDeviceStatus(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return result;
        let items = JSON.parse(localStorage.getItem('definitions'));
        let data = items.filter(q => q.type == 'device_status');
        if (data != null) 
        {
            if (params != null && params.viewtype == 'vselect') {
                data.forEach(q => 
                {
                    result.push({ label: q.name, value: q.definition_id  })
                });
            }
            else {
                result = data;
            }
        }
        result.unshift({ label:'Seçin',value:'0' })
        return result;
    }


    static getPaymentCategories(params) {
        let result = [];
        if (localStorage.getItem('definitions') == null) return result;
        let items = JSON.parse(localStorage.getItem('definitions'));
        let data = items.filter(q => q.type == 'payment_category');
        if (data != null) {
            if (params != null && params.viewtype == 'vselect') {
                data.forEach(q => {
                    result.push(
                        {
                            label: q.name,
                            value: q.definition_id,
                            definition_id: q.definition_id,
                            definition_name: q.definition_name,
                            is_active: q.is_active,
                            type: q.type
                        }
                    )
                });
            }
            else {
                result = data;
            }
        }
        result.unshift({  label:'Seçin', value:'0' , definition_id:'0' })
        return result;
    }

    static getDeviceProblemTypes() {
        let result = [{
            label: "Problem",
            value: "problem",
        },
        {
            label: "Sorun",
            value: "trouble",
        },
        {
            label: "Öneri",
            value: "suggestion",
        }
        ]
        return result;
    }

    static customer_report_type() {
        let result = [{
            label: "Yapılacak",
            value: "will_done",
        },
        {
            label: "Yapıldı",
            value: "done",
        }
        ]
        return result;
    }

    static getDeviceProblemStatus(val = '') {

        let items = [{
            label: "Beklemede",
            value: "pending",
        },
        {
            label: "Onaylandı",
            value: "approved",
        },
        {
            label: "Reddedildi",
            value: "rejected",
        },
        ]
        if (val == '') {
            return items;
        } else {
            return items.find(q => q.value == val);
        }
    }

    static getUserAccessLevel(params) {
        let result = [];
        let data = [
            {
                label: "1.Seviye",
                value: "1",
            },
            {
                label: "2.Seviye",
                value: "2",
            },
            {
                label: "3.Seviye",
                value: "3",
            }
        ]
        if (data != null) {
            if (params != null && params.viewtype == 'vselect') {
                data.forEach(q => {
                    result.push({ label: q.label, value: q.value })
                });
            }
            else {
                result = data;
            }
        }
        console.log('resultxxx', result);
        return result;
    }


    static notificationToast(type = "warning", title = "", text = "") {
        Vue.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
                title: title,
                icon: "AlertCircleIcon",
                variant: type,
                text: text,
            },
        });
    }

    static isNullOrEmpty(data) {
        let result = false;
        if (data == null || data == '' || data == 'undefined') {
            result = true;
        }
        return result;
    }

    static generateUniq(params) {
        let size = 10;
        let pattern = "1234567890abcdefghijklmnoprstuvyzxw";
        //if (params != null && params.size != null) 
        if (params != null) {
            if (params.size != null) {
                size = params.size;
            }
            if (params.number != null && params.number == true) {
                pattern = "1234567890";
            }
        }
        const nanoid = customAlphabet(pattern, size);
        let result = nanoid();
        if (params != null) {
            if (params.upper != null && params.upper == true) {
                result = result.toUpperCase();
            }
        }
        return result;

    }

    static generateUniqid() {
        return nanoid();
    }

    static moment(v) {
        return moment(v);
    }

    static textTruncate(val,limit=100)
    {
        let result = val;
        if(val.length > 100) result = val.substring(0,limit);
        return result;
    }

    static logError(error, page, data = '') {

        let d = JSON.parse(localStorage.getItem('error'));
        if (d == null) d = [];
        let date = this.moment().format('DD.MM.YYYY HH:mm:ss');
        d.push({
            'error': error,
            url: window.location.href,
            moment: date,
            user_id: this.getActiveUser().user_id,
            uniq: this.generateUniq(),
            page: page,
            data: data
        });
        let errorName = `ERROR #${error.message}#`;
        localStorage.setItem('error', JSON.stringify(d));
        console.group(errorName);
        console.error(error);
        console.groupEnd(errorName);
        
    }

}

export default Utils