const { default: axios } = require("axios");
import { env } from 'process';
import utils from "./utils";

class ApiService {
    constructor() { }
    static api_config =
    {
        
        url:process.env.NODE_ENV.toLowerCase() == 'development' ? 'https://localhost/tka_app/api/api':'https://api.tkabilisim.com.tr/api'
        //url: 'https://localhost/tka_app/api/api'
        //url: 'https://api.tkabilisim.com.tr/api'
    }

    static async action(action, data) {

        axios.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
        axios.defaults.headers['developer'] = 'kadir_m.oglu@hotmail.com';

        return new Promise(resolve => {
            axios.post(this.api_config.url, {
                action,
                ...data
            }).then(q => {
                let response = q.data;
                if ([-1, -2].indexOf(response.code) > -1) {
                    console.error('LOGOUT');
                    utils.logout();
                }
                resolve(response);
            })
        })
    }

    static async upload(file, data) {
        //file -> file nesnesi
        //data -> post edilecek datalar
        axios.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
        axios.defaults.headers['developer'] = 'kadir_m.oglu@hotmail.com';
        axios.defaults.headers['content-type'] = 'multipart/form-data';

        let formData = new FormData();
        formData.append('file', file);
        if (data != null) {
            Object.entries(data).forEach(q => {
                formData.append(q[0], q[1]);
            });
        }
        return new Promise(resolve => {
            axios.post(this.api_config.url,
                formData).then(q => {
                    let response = q.data;
                    if ([-1, -2].indexOf(response.code) > -1) {
                        console.error('LOGOUT');
                        utils.logout();
                    }
                    resolve(response);
                })
        })

    }

    static async syncDefinitions() {

        axios.defaults.headers['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
        axios.defaults.headers['developer'] = 'kadir_m.oglu@hotmail.com';
        axios.post(this.api_config.url, {
            "action": "getRequiredDefinitions"
        }).then(q => {
            let response = q.data;
            if (response.success) {
                localStorage.setItem('definitions', JSON.stringify(response.data.definitions));
                localStorage.setItem('city', JSON.stringify(response.data.city));
                localStorage.setItem('district', JSON.stringify(response.data.district));
            }

        })
    }
    static async getBrands() {
        return new Promise(resolve => {
            axios.post(this.api_config.url, {
                'action': 'getBrands'
            }).then(q => {
                resolve(q.data);
            })
        })
    }

    static async downloadFile(target, downloadfilename) {
        axios.post(this.api_config.url,
            {
                action: 'getBlobFile',
                filename: target
            },
            {
                responseType: 'blob'
            }).then(q => {
                if (q) {

                    let blob = new Blob([q.data],)
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = downloadfilename
                    link.click()

                }
            })
    }

};


export default ApiService;
