import Vue from 'vue'
// @ts-ignore
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

const VueUploadComponent = require('vue-upload-component')
Vue.component('file-upload', VueUploadComponent)
Vue.component(FeatherIcon.name, FeatherIcon)

Vue.directive('uppercase', {
    // @ts-ignore
    update(el, binding, vnode) {
        // @ts-ignore
        el.value = el.value.toLocaleUpperCase('tr-TR')
    }
})
